import { howToVideos } from '../../utils/app-data';
import { sortVidArrAlphabetically } from '../../utils/app-constants';
import s from './HowToVideos.module.scss';
import playButton from '../../assets/icons/playButton.svg';

const HowToVideos = ({ handlePlayVideo, selectedProductTypeId, selectedVideoCategory, kioskLocation }) => {
  const videoArr = (kioskLocation === 'gym' || kioskLocation === 'reset') ?
    howToVideos[howToVideos.aliases[kioskLocation]][selectedVideoCategory]
    : howToVideos[howToVideos.aliases[kioskLocation]][selectedProductTypeId].sort(sortVidArrAlphabetically);

  return (
    <section className={s.howToContainer}>
      {videoArr.map((vidObj, i) => (
        <div className={s.videoBox} onClick={() => handlePlayVideo(vidObj.file)} key={`vid${i}`}>
          <div className={s.vidThumbnailContainer} style={{backgroundImage: `url(${vidObj.image})`}}>
            <img src={playButton} alt="Play" className={s.playBtn} />
          </div>
          <h4>{vidObj.title}</h4>
        </div>
      ))}
    </section>
  )
};

export default HowToVideos;