import s from './compareitem.module.scss';

const CompareItem = ({ title, price, salePrice = null, saleName = null, features, image, colorDots = [] }) => {
  const featureList = features.map((feat, i) => <li key={`${feat}-${i}`}>{feat}</li>);
  const currencyFormatting = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0 // Truncates decimal places if price is whole number
  };

  return (
    <div className={s.root}>
      <div className={s.imageContainer}>
        <img src={image} alt="device" className={s.image} />
      </div>
      <div className={s.headerSection}>
        <div>
          {saleName ?
            <h4 className={s.saleTitle}>{saleName}</h4>
            :
            <h4 className={s.saleTitle} style={{ visibility: 'hidden' }}>SALE</h4>
          }
          <h3 className={s.title}>{title}</h3>
          <p className={s.price}>
            {salePrice ?
              <>
                <span className={s.originalPrice}>
                  {new Intl.NumberFormat('en-GB', currencyFormatting).format(price)}
                </span>
                <span className={s.salePrice}>
                  {new Intl.NumberFormat('en-GB', currencyFormatting).format(salePrice)}
                </span>
              </>
              : new Intl.NumberFormat('en-GB', currencyFormatting).format(price)
            }
          </p>
        </div>
        <div className={s.colorDotsContainer}>
          {colorDots.map((colorDot, i) =>
            <span className={s.colorDot} key={`clrdt-${i}`} style={{backgroundColor: colorDot}} />
          )}
        </div>
      </div>
      <p className={s.featureTitle}>FEATURES</p>
      <ul className={s.features}>
        {featureList}
      </ul>
    </div>
  )
};

export default CompareItem;