import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import s from './home.module.scss';
import therabodyLogo from '../../assets/icons/therabodyLogo.svg';

const HomePage = ({vidSrc = 'https://content.jwplatform.com/videos/DUFzWRJJ-vgrxMTsP.mp4'}) => {
  const queryStr = useLocation().search;
  
  return (
    <main className={s.homePageContainer}>
      <video className={s.homeBackgroundVid} autoPlay muted loop>
        <source src={vidSrc} type="video/mp4"/>
      </video>
      <img src={therabodyLogo} alt="Therabody logo" className={s.therabodyLogo}/>
      {queryStr === '?store=gym' ?
        <Link to={{ pathname: '/videos', search: queryStr }} className={s.getStartedLink}>
          GET STARTED
        </Link>
        :
        <Link to={{ pathname: '/products', search: queryStr }} className={s.getStartedLink}>
          GET STARTED
        </Link>
      }
    </main>
  )
};

export default HomePage;