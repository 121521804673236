import React from 'react';
import classnames from 'classnames';
import s from './VideoCategoryCard.module.scss';

export const VideoCategoryCard = ({
  vidCat,
  vidCatIcon,
  vidCatTitle,
  isSelected = false,
  isDifferentOpacity = false,
  handleSelectVideoCategory
}) => (
  <div
    className={classnames(
      s.videoCategoryCard,
      { [s.active]: isSelected },
      { [s.isDifferentOpacity]: isDifferentOpacity },
    )}
    onClick={() => handleSelectVideoCategory(vidCat)}
  >
    <img
      src={vidCatIcon}
      className={s.videoCategoryImg}
      alt="Video category icon"
    />
    <h3 className={s.videoCategoryTitle}>{vidCatTitle}</h3>
  </div>
);