import React from 'react';
import classnames from 'classnames';
import s from './ProductTypeCard.module.scss';

export const ProductTypeCard = ({
	productType,
	onSelectProductType = () => {},
	isSelected = false,
	isDifferentOpacity = false,
	isVisible = true,
}) =>
	isVisible && (
		<div
			className={classnames(s.productType, { [s.active]: isSelected }, { [s.isDifferentOpacity]: isDifferentOpacity })}
			onClick={() => onSelectProductType(productType)}
		>
			<img src={productType.img} alt={productType.name} className={classnames(s.listImage)} />
			<h3>{productType.name}</h3>
			<h4>{productType.title}</h4>
		</div>
	);
