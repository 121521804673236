import ReactJWPlayer from 'react-jw-player';
import s from './HowToPlayer.module.scss';
import CloseBtn from '../../assets/icons/exit.svg';

const HowToPlayer = ({ openedVidUrl, closeVidPlayer }) => (
  <div className={s.videoPlayerOverlay}>
    <div className={s.videoPlayerContainer}>
      <div className={s.closeBtnContainer}>
        <img src={CloseBtn} alt="Close button" className={s.closeBtn} onClick={closeVidPlayer} />
      </div>
      <ReactJWPlayer
        playerId='kiosk-how-to-player'
        playerScript='https://cdn.jwplayer.com/libraries/ga0HE7Pb.js'
        file={openedVidUrl}
      />
    </div>
  </div>
);

export default HowToPlayer;