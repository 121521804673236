import React from 'react';
import { ProductCard } from '../ProductCard';
import { products } from '../../utils/app-data';
import s from './ProductSubcategoryNav.module.scss';

const ProductSubcategoryNav = ({ selectedProductTypeId = null, selectedProductId = null, handleSelectProductId = () => {} }) => (
  <nav className={s.subcategoryNav}>
    {products
      .filter(p => p.productTypeId === selectedProductTypeId)
      .filter(p => p.isProduct)
      .map(p => (
        <ProductCard
          isSelected={ selectedProductId === p.id }
          key={p.id}
          product={p}
          onSelectProduct={() => handleSelectProductId(p.id)}
        />
      ))
    }
  </nav>
);

export default ProductSubcategoryNav;