// import { useState, useRef } from 'react';
import { useRef } from 'react';
import s from "./EmailCapture.module.scss";
import EmailCaptureBackground from '../../assets/EmailCapture/emailCaptureBackground.png';
import CloseBtn from '../../assets/icons/exit.svg';

const EmailCapture = ({ closeEmailCapture }) => {
  const emailInputVal = useRef();
  // const [invalidEmail, setInvalidEmail] = useState(false);

  const submitEmailAddress = async (e, emailAddress) => {
    e.preventDefault();
    console.log(emailAddress);
  
  //   try {
  //     const response = await fetch('api.emarsys.net/api/v2', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ emailAddress: emailAddress })
  //     });
  //     const responseObj = await response.json();
  //     const emailFailed = responseObj.status >= 400 ? true : false;
  //     setInvalidEmail(emailFailed);
  //   } catch (err) {
  //     console.error(err);
  //   }
  }

  return (
    <div className={s.emailCaptureOverlay}>
      <div className={s.emailBanner} style={{backgroundImage: `url(${EmailCaptureBackground})`}}>
        <img src={CloseBtn} alt="Close button" className={s.closeBtn} onClick={() => closeEmailCapture()} />
        <h2 class={s.emailCaptureSubheading}>Get the latest and greatest</h2>
        <h1 class={s.emailCaptureHeading}>Be the first to know about offers, and more</h1>
        <form onSubmit={(e) => submitEmailAddress(e, emailInputVal.current.value)} className={s.emailForm}>
          <input
            ref={emailInputVal}
            className={s.emailInput}
            type="email"
            placeholder="Email Address"
            required
            autoFocus
          />
          <button className={s.emailSubmitBtn}>Sign Up</button>
          {/* <p className={s.emailError}>*Something went wrong</p> */}
        </form>
        {/* {invalidEmail ? <p className={s.emailError}>*Something went wrong</p> : null} */}
      </div>
    </div>
  );
}

export default EmailCapture;