import React, { useState, useEffect } from 'react';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory
} from 'react-router-dom';
import ProductPage from '../Product';
import ComparePage from '../Compare';
import HowToPage from '../HowToVideos';
import TopNav from '../../components/TopNav';
import Sidebar from '../../components/Sidebar';
import { navTabs } from "../../utils/app-constants";
import { products, productTypes } from '../../utils/app-data';
import WhatsIncluded from '../../components/WhatsIncluded';
import EmailCapture from '../../components/EmailCapture';
import HowToPlayer from '../../components/HowToPlayer';

const AppFrame = () => {
  const history = useHistory();
  const location = useLocation();
  const storeParam = location.search || '?store=retail';
  const kioskLocation = storeParam.split('=')[1];

  // Kiosk PWA will be bookmarked without path - need to hardcode activeTab initialization
  const pathSplit = location.pathname.split('/');
  const [activeTab, setActiveTab] = useState(pathSplit[1] || 'products');

  // Use the product category ID in URL to retrieve a list of product objects in that category (e.g. Theragun Pro, Elite, Prime).
  // If ID not present in URL, arbitrarily grab first product in products array.
  const initProductTypeId = pathSplit.length > 2 ? parseInt(pathSplit[2]) : productTypes[0].id;
  const initProductObj = products.find(p => p.productTypeId === initProductTypeId);

  // Sidebar selections will change product category ID and subcategory nav selections will change product ID.
  // Fall back to first product in products array if URL ID doesn't match any product categories.
  const [selectedProductTypeId, setSelectedProductTypeId] = useState(initProductObj ? initProductObj.productTypeId : products[0].productTypeId);
  const [selectedProductId, setSelectedProductId] = useState(initProductObj ? initProductObj.id : products[0].id);

  const [selectedVideoCategory, setSelectedVideoCategory] = useState('warmup');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openedVidUrl, setOpenedVidUrl] = useState(null);
  const [isEmailCaptureOpen, setIsEmailCaptureOpen] = useState(false);

  useEffect(() => {
    // Navigate home after 10 mins of inactivity unless user is viewing a video
    if (!openedVidUrl) {
      // useEffect runs after every render, so timer will reset after every user action
      const homeResetInterval = 10 * 60 * 1000; // m * s * ms = 10 min
      const goHomeWhenInactive = setTimeout(() => history.push('/' + storeParam), homeResetInterval);
      return () => clearTimeout(goHomeWhenInactive);
    }
  });

  const handleNavChange = tabIndex => {
    const clickedTabObj = navTabs[tabIndex];
    setActiveTab(clickedTabObj.title);
    let newUrlPath = clickedTabObj.route;

    if (clickedTabObj.title === 'videos') {
      // Resets product category selection since Sidebar doesn't have functionality in Videos tab
      setSelectedProductTypeId(productTypes[0].id);
      newUrlPath += '/' + storeParam;
    } else {
      // Retain product category selection (e.g. RecoveryAir) across tabs and reflect this in URL
      newUrlPath += '/' + selectedProductTypeId + storeParam;
      // Behavior A: Retain product subcategory selection (e.g. Elite, Pro) across tabs
      // setSelectedProductId(selectedProductId);
      // Behavior B: Reset product subcategory across tabs
      setSelectedProductId(products.find(p => p.productTypeId === selectedProductTypeId).id);
    }

    history.push(newUrlPath);
  }

  const handleSelectProductTypeId = productTypeId => {
    setSelectedProductTypeId(productTypeId);
    if (activeTab !== 'videos') {
      const setProduct = products.find(p => p.productTypeId === productTypeId);
      setSelectedProductId(setProduct ? setProduct.id : null);
      history.push('/' + activeTab + (productTypeId ? '/' + productTypeId : '') + storeParam);
    }
  }

  return (
    <>
      <TopNav
        activeNav={activeTab}
        handleNavChange={handleNavChange}
        handleOpenEmailCapture={() => setIsEmailCaptureOpen(true)}
        kioskLocation={kioskLocation}
      />
      <Sidebar
        selectedProductTypeId={selectedProductTypeId}
        handleSelectProductTypeId={handleSelectProductTypeId}
        activeTab={activeTab}
        kioskLocation={kioskLocation}
        selectedVideoCategory={selectedVideoCategory}
        handleSelectVideoCategory={vidCat => setSelectedVideoCategory(vidCat)}
      />
      <div className="App-page">
        <Switch>
          <Redirect exact from="/products" to={'/products/1' + storeParam} />
          <Route exact path="/products/:id" component={() =>
            <ProductPage
              selectedProductTypeId={selectedProductTypeId}
              productId={selectedProductId}
              handleSelectProductId={setSelectedProductId}
            />
          } />
          <Route exact from="/compare/:id" component={() =>
            <ComparePage productTypeId={selectedProductTypeId} />
          } />
          <Route exact from="/videos" component={() =>
            <HowToPage
              handlePlayVideo={(vidUrl) => setOpenedVidUrl(vidUrl)}
              selectedProductTypeId={selectedProductTypeId}
              selectedVideoCategory={selectedVideoCategory}
              kioskLocation={kioskLocation}
            />
          } />
          <Redirect from="*" to={'/products/1' + storeParam} />
        </Switch>
      </div>
      {activeTab === 'products' &&
        <WhatsIncluded
          productId={selectedProductId}
          onDrawerOpen={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      }
      {openedVidUrl &&
        <HowToPlayer
          openedVidUrl={openedVidUrl}
          closeVidPlayer={() => setOpenedVidUrl(null)}
        />
      }
      {isEmailCaptureOpen &&
        <EmailCapture
          closeEmailCapture={() => setIsEmailCaptureOpen(false)}
        />
      }
    </>
  );
}

export default AppFrame;