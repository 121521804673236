import classnames from 'classnames';
import CompareItem from './CompareItem';
import { products } from '../../utils/app-data';
import s from './compare.module.scss';

const ComparePage = ({ productTypeId = '' }) => {
  const productList = products.filter(e => e.isCompare && e.productTypeId === productTypeId);

  return (
    <div className={classnames(s.comparePage, s[`items_${productList.length}`])}>
      {productList.map((item) => (
        <CompareItem
          key={item.title}
          image={item.img}
          title={item.title}
          price={item.price}
          salePrice={item.salePrice}
          saleName={item.saleName}
          features={item.features}
          colorDots={item.colorDots}
        />
      ))}
    </div>
  );
};

export default ComparePage;