import ProductSubcategoryNav from '../../components/ProductSubcategoryNav';
import { products } from '../../utils/app-data';
import s from './product.module.scss';

const ProductPage = ({
  selectedProductTypeId = '',
  productId = '',
  handleSelectProductId = () => {}
}) => {
  const selectedProduct = products.find(p => p.id === productId);

  return selectedProduct ? (
    <div className={s.productPage} >
      <img
          src={selectedProduct && selectedProduct.heroImg}
          alt={'Therabody dashboard'}
          className={s.productHeroImg}
      />
      <ProductSubcategoryNav
        selectedProductTypeId={selectedProductTypeId}
        selectedProductId={productId}
        handleSelectProductId={handleSelectProductId}
      />
    </div>
  ) : <div />;
};

export default ProductPage;