export const navTabs = [
  {
    displayName: 'Products',
    title: 'products',
    route: '/products'
  },
  {
    displayName: 'Compare',
    title: 'compare',
    route: '/compare'
  },
  {
    displayName: 'How-To Videos',
    title: 'videos',
    route: '/videos'
  }
];

export const sortVidArrAlphabetically = ({ title: a }, { title: b }) => (
  a.toLowerCase() < b.toLowerCase() ? -1
    : a.toLowerCase() > b.toLowerCase() ? 1
    : 0
);