import React, { useState } from 'react';
import s from './WhatsIncluded.module.scss';
import classnames from 'classnames';
import { products } from '../../utils/app-data';

const getProductInfo = (prodId) => {
  return products.find((p) => p.id === prodId);
};

const WhatsIncluded = ({ productId, onDrawerOpen }) => {
  const [isLeft, setIsLeft] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [direction, setDirection] = useState();
  const [currentX, setCurrentX] = useState();

  const focusItem = getProductInfo(productId);

  const handleDrawerOpen = () => {
    onDrawerOpen(true);
    setIsLeft(true);
    setIsRight(false);
  };

  const handleDrawerClose = () => {
    onDrawerOpen(false);
    setIsLeft(false);
    setIsRight(true);
  };

  const handleTouch = (e) => {
    e.preventDefault();
    let touch = e.touches[0].clientX;
    if (currentX > touch) setDirection('left');
    else if (currentX < touch) setDirection('right');
  };

  const handleTouchEnd = () => {
    if (direction === 'left') {
      handleDrawerOpen();
      setDirection('right');
    } else if (direction === 'right') {
      handleDrawerClose();
      setDirection('left');
    }
  };

  const handleClick = () => {
    if (!isLeft && !isRight) {
      handleDrawerOpen();
    } else if (isLeft) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    let touch = e.touches[0].clientX;
    setCurrentX(touch);
  };
  return (
    <div
      className={classnames(
        s.root,
        isLeft && s.slideLeft,
        isRight && s.slideRight
      )}
      style={{zIndex: isLeft ? '2' : '0'}} // Stops WhatsIncluded overlay from blocking Email Capture btn
    >
      <span className={s.bg}></span>
      <div className={s.container}>
        <div
          className={s.tab}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouch}
          onTouchEnd={handleTouchEnd}
          onClick={handleClick}
        >
          <hr className={s.tabLine} />
          <p className={s.tabText}>What's Included</p>
        </div>
        <div className={s.innerContainer}>
          <div className={s.contentContainer}>
            <div
              className={s.imageContainer}
              style={{
                backgroundImage: `url(${focusItem.includedImg})`,
              }}
            ></div>
            <div className={s.descriptionContainer}>
              <div>
                <div className={s.detailsSection}>
                  <h2 className={s.title}>
                    {focusItem.title1 || focusItem.title}
                  </h2>
                  {focusItem.descriptions.map((description, i) => (
                    <p key={`itemDescription${i}`} className={s.description}>
                      {description}
                    </p>
                  ))}
                  <ul className={s.list}>
                    {focusItem.itemsIncluded.map((item, i) => (
                      <li key={`itemIncluded${i}`}>{item}</li>
                    ))}
                  </ul>
                </div>
                {focusItem.attachments && (
                  <div className={s.attachmentSection}>
                    <h2 className={s.title}>Attachments</h2>
                    <p className={s.description}>
                      {focusItem.attachments.description}
                    </p>
                    <div className={s.table}>
                      <table>
                        <tbody>
                          {focusItem.attachments.items.map((item, i) => (
                            <tr key={`attachment${i}`}>
                              <td className={s.attachmentTitle}>{item.title}</td>
                              <td className={s.attachmentDescription}>{item.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsIncluded;
