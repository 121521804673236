import React from 'react';
import classnames from 'classnames';
import s from './ProductCard.module.scss';

export const ProductCard = ({ product, onSelectProduct, isSelected }) => (
  <div
    className={classnames(s.product)}
    onClick={() => onSelectProduct(product)}
  >
    <img
      src={product.img}
      alt={product.name}
      className={classnames(s.listImage, isSelected && s.active)}
    />
    <h3>{product.name}</h3>
  </div>
);
