import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import AppFrame from './pages/AppFrame';
import './App.css';
import './styles/global.scss';

document.title = 'Recovery Station';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="*" component={AppFrame}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
