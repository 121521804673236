// Product category images
import theraGunImage from '../assets/Theragun/theragun.png';
import recoveryAirImage from '../assets/RecoveryAir/RecoveryAir.png';
import waveSeriesImage from '../assets/WaveSeries/WaveSeries.png';
import powerDotImage from '../assets/PowerDot/PowerDot.png';

// Theragun images
import proImg from '../assets/Theragun/Pro/pro.png';
import proHeroImg from '../assets/Theragun/Pro/proHero.png';
import proIncludedImg from '../assets/Theragun/Pro/proIncluded.png';

import eliteImg from '../assets/Theragun/Elite/elite.png';
import eliteHeroImg from '../assets/Theragun/Elite/eliteHero.png';
import eliteIncludedImg from '../assets/Theragun/Elite/eliteIncluded.png';

import primeImg from '../assets/Theragun/Prime/prime.png';
import primeHeroImg from '../assets/Theragun/Prime/primeHero.png';
import primeIncludedImg from '../assets/Theragun/Prime/primeIncluded.png';

import miniImg from '../assets/Theragun/Mini/mini.png';
import miniHeroImg from '../assets/Theragun/Mini/miniHero.png';
import miniIncludedImg from '../assets/Theragun/Mini/miniIncluded.png';

// Wave Series images
import waveSoloImg from '../assets/WaveSeries/WaveSolo/waveSolo.png';
import waveSoloHeroImg from '../assets/WaveSeries/WaveSolo/waveSoloHero.png';
import waveSoloIncludedImg from '../assets/WaveSeries/WaveSolo/waveSoloIncluded.png';

import waveDuoImg from '../assets/WaveSeries/WaveDuo/waveDuo.png';
import waveDuoHeroImg from '../assets/WaveSeries/WaveDuo/waveDuoHero.png';
import waveDuoIncludedImg from '../assets/WaveSeries/WaveDuo/waveDuoIncluded.png';

import waveRollerImg from '../assets/WaveSeries/WaveRoller/waveRoller.png';
import waveRollerHeroImg from '../assets/WaveSeries/WaveRoller/waveRollerHero.png';
import waveRollerIncludedImg from '../assets/WaveSeries/WaveRoller/waveRollerIncluded.png';

// RecoveryAir images
import recoveryAirImg from '../assets/RecoveryAir/RecoveryAir/recoveryAir.png';
import recoveryAirHeroImg from '../assets/RecoveryAir/RecoveryAir/recoveryAirHero.png';
import recoveryAirIncludedImg from '../assets/RecoveryAir/RecoveryAir/recoveryAirIncluded.png';

import recoveryAirProImg from '../assets/RecoveryAir/RecoveryAirPro/recoveryAirPro.png';
import recoveryAirProHeroImg from '../assets/RecoveryAir/RecoveryAirPro/recoveryAirProHero.png';
import recoveryAirProIncludedImg from '../assets/RecoveryAir/RecoveryAirPro/recoveryAirProIncluded.png';

// Power-dot images
import powerDotUnoImg from '../assets/PowerDot/Uno/powerDotUno.png';
import powerDotUnoHeroImg from '../assets/PowerDot/Uno/powerDotUnoHero.png';
import powerDotUnoIncludedImg from '../assets/PowerDot/Uno/powerDotUnoIncluded.png';

import powerDotDuoImg from '../assets/PowerDot/Duo/powerDotDuo.png';
import powerDotDuoHeroImg from '../assets/PowerDot/Duo/powerDotDuoHero.png';
import powerDotDuoIncludedImg from '../assets/PowerDot/Duo/powerDotDuoIncluded.png';

import powerDotBundleImg from '../assets/PowerDot/Bundle/powerDotBundle.png';

// Video category icons
import warmUpIcon from '../assets/VideoCategoryIcons/warmUp.svg';
import recoverIcon from '../assets/VideoCategoryIcons/recover.svg';
import ailmentsIcon from '../assets/VideoCategoryIcons/ailments.svg';
import lifestyleIcon from '../assets/VideoCategoryIcons/lifestyle.svg';

export const productTypes = [
  {
    id: 1,
    name: 'Theragun',
    img: theraGunImage,
    title: 'PERCUSSIVE THERAPY'
  },
  {
    id: 2,
    name: 'Wave Series',
    img: waveSeriesImage,
    title: 'SMART VIBRATION THERAPY',
  },
  {
    id: 3,
    name: 'RecoveryAir',
    img: recoveryAirImage,
    title: 'COMPRESSION THERAPY',
  },
  {
    id: 4,
    name: 'PowerDot',
    img: powerDotImage,
    title: 'MUSCLE STIMULATION'
  }
];

export const products = [
  {
    id: 1,
    productTypeId: 1,
    isProduct: true,
    isCompare: true,
    name: 'PRO',
    title: 'PRO',
    img: proImg,
    heroImg: proHeroImg,
    includedImg: proIncludedImg,
    price: 549,
    features: [
      '60 lbs no-stall force',
      'Superior sound insulation',
      '16mm amplitude',
      'Custom speed range (1750 - 2400 PPMs)',
      '300 minute battery life',
      '6 attachments',
      'OLED screen',
      'Force meter',
      'Rotating arm',
      '2 external batteries',
    ],
    descriptions: [
      'The deep muscle treatment pros trust with the durability and features they rely on.',
    ],
    itemsIncluded: [
      'Protective Carrying Case',
      '6 Attachments with Travel Pouch',
      '2x Rechargeable Lithium-ion Batteries',
      'Lithium-ion Battery Charger',
    ],
    attachments: {
      description:
        'Six different attachments expertly designed for the unique needs of all muscle groups.',
      items: [
        { title: 'Dampener', description: 'Tender areas or near bones' },
        { title: 'Supersoft', description: 'Sensitive, sore muscles' },
        {
          title: 'Standard Ball',
          description: 'Large and small muscle groups',
        },
        { title: 'Wedge', description: 'Scraping, shoulder blades' },
        { title: 'Thumb', description: 'Trigger points, and lower back' },
        { title: 'Cone', description: 'Pinpoint muscle treatment' },
      ],
    },
    colorDots: [ '#1e1f21', '#ab1a2d' ]
  },
  {
    id: 2,
    productTypeId: 1,
    isProduct: true,
    isCompare: true,
    name: 'Elite',
    title: 'Elite',
    img: eliteImg,
    heroImg: eliteHeroImg,
    includedImg: eliteIncludedImg,
    price: 375,
    features: [
      '40 lbs no-stall force',
      'Quietest device',
      '16mm amplitude',
      'Custom speed range (1750 - 2400 PPMs)',
      '120 minute battery life',
      '5 attachments',
      'OLED screen',
      'Force meter',
    ],
    descriptions: [
      'Our powerful deep muscle treatment, in an ultra-quiet smart percussive therapy device with advanced sound insulation.',
      'Elite works deep to melt away tension and release soreness, empowering you to care for your body daily.',
    ],
    itemsIncluded: [
      'Protective Carrying Case',
      '5 Attachments',
      'Power Adapter',
    ],
    attachments: {
      description:
        'Five different attachments expertly designed for the unique needs of all muscle groups.',
      items: [
        { title: 'Dampener', description: 'Tender areas or near bones' },
        {
          title: 'Standard Ball',
          description: 'Large and small muscle groups',
        },
        { title: 'Wedge', description: 'Scraping, shoulder blades' },
        { title: 'Thumb', description: 'Trigger points, and lower back' },
        { title: 'Cone', description: 'Pinpoint muscle treatment' },
      ],
    },
    colorDots: [ '#1e1f21', '#f5f5f5', '#ab1a2d' ]
  },
  {
    id: 3,
    productTypeId: 1,
    isProduct: true,
    isCompare: true,
    name: 'Prime',
    title: 'Prime',
    img: primeImg,
    heroImg: primeHeroImg,
    includedImg: primeIncludedImg,
    price: 275,
    features: [
      '30 lbs no-stall force',
      'Quiet for any setting',
      '16mm amplitude',
      'Custom speed range (1750 - 2400 PPMs)',
      '120 minute battery life',
      '4 attachments',
    ],
    descriptions: [
      "This simplified smart percussive therapy device prioritizes the essential features you need while maintaining the power and effectiveness of Theragun's deep muscle treatment.",
      'Ease discomfort, soothe tightness and tension, and recover faster in seconds.',
    ],
    itemsIncluded: [
      'Protective Carrying Case',
      '4 Attachments',
      'Power Adapter',
    ],
    attachments: {
      description:
        'Four different attachments expertly designed for the unique needs of all muscle groups.',
      items: [
        { title: 'Dampener', description: 'Tender areas or near bones' },
        {
          title: 'Standard Ball',
          description: 'Large and small muscle groups',
        },
        { title: 'Thumb', description: 'Trigger points, and lower back' },
        { title: 'Cone', description: 'Pinpoint muscle treatment' },
      ],
    },
    colorDots: [ '#1e1f21' ]
  },
  {
    id: 4,
    productTypeId: 1,
    isProduct: true,
    isCompare: true,
    name: 'mini',
    title: 'mini',
    img: miniImg,
    heroImg: miniHeroImg,
    includedImg: miniIncludedImg,
    price: 175,
    features: [
      '20 lbs no-stall force',
      'Quiet for any setting',
      '12mm amplitude',
      'Three built-in speeds (1750, 2100, 2400 PPMs)',
      '150 minute battery life',
      '1 attachment',
    ],
    descriptions: [
      'The mini is your pocket-sized partner, giving you Theragun quality muscle treatment with unparalleled portability.',
      'Compact but powerful, Theragun mini is the most agile massage device that goes wherever you do.',
    ],
    itemsIncluded: [
      'Soft case (not shown)',
      'Standard Ball Attachment',
      'Power Adapter',
    ],
    attachments: {
      description:
        'An expertly designed attachment for the unique needs of all muscle groups.',
      items: [
        {
          title: 'Standard Ball',
          description: 'Large and small muscle groups',
        },
      ],
    },
    colorDots: [ '#1e1f21', '#f5f5f5', '#ab1a2d', '#d7b1a9' ]
  },
  {
    id: 5,
    productTypeId: 2,
    isProduct: true,
    isCompare: true,
    name: 'Wave Solo',
    title: 'Wave Solo',
    img: waveSoloImg,
    heroImg: waveSoloHeroImg,
    includedImg: waveSoloIncludedImg,
    price: 68,
    features: [
      'Pinpointed, focused vibration',
      'Innovative wave texture',
      'Ultra-portable',
      'Efficiently mutes reverberation',
      'Hypo-allergenic textured silicone',
      '3 vibration intensity settings',
      'Bluetooth Connectivity',
      'USB-C enabled',
      '120 minute total battery life',
      'Carrying pouch',
      'USB-A to USB-C cable',
    ],
    descriptions: [
      'This ultra-portable smart vibration therapy device delivers pinpointed pressure and vibration to focused areas for reduced tension and improved movement.',
      'Three powerful vibration frequencies and innovative wave texture allow its ergonomic shape to work deep into hard-to-reach areas, while our innovative QuietRoll Technology™ effectively mutes sound and reverberations.',
    ],
    itemsIncluded: [
      'Protective Carrying Pouch (Not Pictured)',
      'USB-A to USB-C cable',
    ],
  },
  {
    id: 6,
    productTypeId: 2,
    isProduct: true,
    isCompare: true,
    name: 'Wave Duo',
    title: 'Wave Duo',
    img: waveDuoImg,
    heroImg: waveDuoHeroImg,
    includedImg: waveDuoIncludedImg,
    price: 89,
    features: [
      'Contoured to the spine',
      'Innovative wave texture',
      'Portable',
      'Hypo-allergenic textured silicone',
      '5 vibration intensity settings',
      'Bluetooth Connectivity',
      'USB-C enabled',
      '200 minute total battery life',
      'Carrying pouch',
      'USB-A to USB-C cable',
    ],
    descriptions: [
      'Ergonomically contoured to the back, spine, and neck, this portable smart vibrating roller is a uniquely versatile rolling solution.',
      'Five powerful vibration frequencies and high-traction wave grooves work to deliver just the right angles of pressure to areas in need—releasing soreness, reducing tension, and improving movement.',
    ],
    itemsIncluded: [
      'Protective Carrying Pouch (Not Pictured)',
      'USB-A to USB-C cable',
    ],
  },
  {
    id: 7,
    productTypeId: 2,
    isProduct: true,
    isCompare: true,
    name: 'Wave Roller',
    title: 'Wave Roller',
    img: waveRollerImg,
    heroImg: waveRollerHeroImg,
    includedImg: waveRollerIncludedImg,
    price: 125,
    features: [
      '12 inches of full-body vibration',
      'Innovative wave texture',
      'On-the-go carrying case',
      'Hypo-allergenic EVA high-density foam',
      '5 vibration intensity settings',
      'Bluetooth Connectivity',
      '180 minute total battery life',
      'Protective carrying pouch',
      'Power adapter',
    ],
    descriptions: [
      'The Wave Roller is constructed to deliver the most powerful and efficient foam rolling experience.',
      'The Wave Roller is Bluetooth® enabled with five intensity settings, which allow you to tailor both your warm-up and recovery conveniently from your mobile device with the Therabody app.',
    ],
    itemsIncluded: [
      'Protective Carrying Pouch (Not Pictured)',
      'Power Adapter',
    ],
  },
  {
    id: 8,
    productTypeId: 3,
    isProduct: true,
    isCompare: true,
    name: 'RecoveryAir',
    title: 'RecoveryAir',
    img: recoveryAirImg,
    heroImg: recoveryAirHeroImg,
    includedImg: recoveryAirIncludedImg,
    price: 599,
    features: [
      'Manual pressure, hold time, and session length',
      'Precise pressure control',
      'Safe pressure-based cycles',
      'True negative gradient of pressure',
      'Easy-to-clean, seamless interior',
      '360 minute total battery life',
      'Compatible with 4-chamber garments only, exclusive to RecoveryAir',
      'Medical grade coated fabric',
    ],
    descriptions: [
      'This easy-to-use pneumatic compression system makes it easy for anybody to experience the benefits of our clinically-proven recovery technology.',
      'Sit back, relax, and recover faster as each boot sequentially compresses the leg to increase circulation and reduce soreness and fatigue. Discover the fastest, safest, most effective compression in recovery.',
    ],
    itemsIncluded: [
      'RecoveryAir pneumatic device',
      'Set of compression boots',
      'Blocker plug (not shown)',
      'DC power adaptor (not shown)',
    ],
  },
  {
    id: 9,
    productTypeId: 3,
    isProduct: true,
    isCompare: true,
    name: 'RecoveryAir PRO',
    title: 'RecoveryAir PRO',
    img: recoveryAirProImg,
    heroImg: recoveryAirProHeroImg,
    includedImg: recoveryAirProIncludedImg,
    price: 1129,
    features: [
      'Full customisation via Bluetooth®',
      '5 guided treatment programs',
      'Precise pressure control',
      'Safe pressure-based cycles',
      'True negative gradient of pressure',
      'Easy-to-clean, seamless interior',
      '240 minute total battery life',
      'Compatible with RecoveryAirPRO 8-chamber garments and RecoveryAir 4-chamber garments',
      'Medical grade coated fabric',
    ],
    descriptions: [
      'Meet the most customisable pneumatic compression system in recovery.',
      'Cut down on hours of rest and recovery as our clinically-proven technology increases circulation and reduce soreness more globally in the body.',
      'From interval mode to individual chamber control, RecoveryAir Pro gives you total control of your recovery session.',
    ],
    itemsIncluded: [
      'RecoveryAir pneumatic device',
      'Set of compression boots',
      'Blocker plug (not shown)',
      'DC power adaptor (not shown)',
    ],
  },
  {
    id: 10,
    productTypeId: 4,
    isProduct: true,
    isCompare: true,
    name: 'Uno',
    title: 'Uno',
    title1: 'PowerDot 2.0 Uno',
    img: powerDotUnoImg,
    heroImg: powerDotUnoHeroImg,
    includedImg: powerDotUnoIncludedImg,
    price: 175,
    features: [
      'Wireless control via Bluetooth®',
      'Guided programs for performance, wellness, pain',
      'Ultra-portable',
      'Lightweight and compact. Hands-free use anywhere',
      'Customised treatments tailored to your needs',
      'FDA-Cleared Class II Medical Device',
      'Protective carrying pouch',
    ],
    descriptions: [
      'This powerful muscle stimulator packs the proven concepts of NMES & TENS into a sleek lightweight pod you can use anywhere.',
      'Fully controlled by the PowerDot app, it takes the guesswork out of electrical muscle stimulation by guiding and customizing your intensity and pad placement. Trusted by everyone from the world’s top athletes, to fitness enthusiasts, and beyond.',
    ],
    itemsIncluded: [
      '1 PowerDot pod',
      '1 set of electrode pads',
      '1 set of lead cables (10 cm and 30 cm)',
      '1 micro USB charging cable',
      'Protective carrying case',
    ],
    colorDots: [ '#1e1f21', '#ab1a2d' ]
  },
  {
    id: 11,
    productTypeId: 4,
    isProduct: true,
    isCompare: true,
    name: 'Duo',
    title: 'Duo',
    title1: 'PowerDot 2.0 Duo',
    img: powerDotDuoImg,
    heroImg: powerDotDuoHeroImg,
    includedImg: powerDotDuoIncludedImg,
    price: 325,
    features: [
      'Wireless control via Bluetooth®',
      'Guided programs for performance, wellness, pain',
      'Lightweight and compact. Hands-free use anywhere',
      'Customised treatments tailored to your needs',
      'FDA-Cleared Class II Medical Device',
      'Protective carrying pouch',
    ],
    descriptions: [
      'This powerful muscle stimulator packs the proven concepts of NMES & TENS into a sleek lightweight pod you can use anywhere.',
      'Fully controlled by the PowerDot app, it takes the guesswork out of electrical muscle stimulation by guiding and customizing your intensity and pad placement. Trusted by everyone from the world’s top athletes, to fitness enthusiasts, and beyond.',
    ],
    itemsIncluded: [
      '2 PowerDot pod',
      '2 set of electrode pads',
      '2 set of lead cables (10 cm and 30 cm)',
      '2 micro USB charging cable',
      'Protective carrying case',
    ],
    colorDots: [ '#1e1f21', '#ab1a2d' ]
  },
];

// ____    ____  __   _______   _______   ______       _______       ___   .___________.    ___      
// \   \  /   / |  | |       \ |   ____| /  __  \     |       \     /   \  |           |   /   \     
//  \   \/   /  |  | |  .--.  ||  |__   |  |  |  |    |  .--.  |   /  ^  \ `---|  |----`  /  ^  \    
//   \      /   |  | |  |  |  ||   __|  |  |  |  |    |  |  |  |  /  /_\  \    |  |      /  /_\  \   
//    \    /    |  | |  '--'  ||  |____ |  `--'  |    |  '--'  | /  _____  \   |  |     /  _____  \  
//     \__/     |__| |_______/ |_______| \______/     |_______/ /__/     \__\  |__|    /__/     \__\ 

export const videoCategories = [
  {
    category: 'warmup',
    displayName: 'Warm-Up',
    icon: warmUpIcon
  },
  {
    category: 'recover',
    displayName: 'Recover',
    icon: recoverIcon
  },
  {
    category: 'ailments',
    displayName: 'Ailments',
    icon: ailmentsIcon
  },
  {
    category: 'lifestyle',
    displayName: 'Lifestyle',
    icon: lifestyleIcon
  },
];

// ReactJWPlayer package requires 'title', 'file', and 'image' for video object property names
export const howToVideos = {
  videoSetOne: {
    1: [
      {
        title: 'Turning On Your Device',
        file: 'https://content.jwplatform.com/videos/vYgqJ7wG-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/vYgqJ7wG-1920.jpg'
      },
      {
        title: 'Hamstrings',
        file: 'https://content.jwplatform.com/videos/9t8iu1hq-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/9t8iu1hq-1920.jpg'
      },
      {
        title: 'Hip Flexors',
        file: 'https://content.jwplatform.com/videos/s5SgrawQ-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/s5SgrawQ-1920.jpg'
      },
      {
        title: 'IT Bands',
        file: 'https://content.jwplatform.com/videos/sUz7JTQ5-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/sUz7JTQ5-1920.jpg'
      },
      {
        title: 'Quads',
        file: 'https://content.jwplatform.com/videos/J69f4DP5-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/J69f4DP5-1920.jpg'
      },
      {
        title: 'Bicep Tendonitis',
        file: 'https://content.jwplatform.com/videos/15Mz2VLl-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/15Mz2VLl-1920.jpg'
      },
      {
        title: 'Bursitis',
        file: 'https://content.jwplatform.com/videos/7e0U2nMR-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/7e0U2nMR-1920.jpg'
      },
      {
        title: 'Carpal Tunnel',
        file: 'https://content.jwplatform.com/videos/rzdcRST4-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/rzdcRST4-1920.jpg'
      },
      {
        title: 'Shin Splints ',
        file: 'https://content.jwplatform.com/videos/5XhMgZGd-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/5XhMgZGd-1920.jpg'
      },
      {
        title: 'Carpal Tunnel (mini)',
        file: 'https://content.jwplatform.com/videos/TfcX4NXe-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/TfcX4NXe-1920.jpg'
      },
      {
        title: 'Golf/Tennis Elbow',
        file: 'https://content.jwplatform.com/videos/bFi7O3l0-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/bFi7O3l0-1920.jpg'
      },
      {
        title: 'Shoulders',
        file: 'https://content.jwplatform.com/videos/PobrGvH5-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/PobrGvH5-1920.jpg'
      },
      {
        title: 'Chest',
        file: 'https://content.jwplatform.com/videos/kkGDOhqn-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/kkGDOhqn-1920.jpg'
      },
      {
        title: 'Deltoids',
        file: 'https://content.jwplatform.com/videos/ZdibVgU3-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/ZdibVgU3-1920.jpg'
      },
      {
        title: 'Forearms',
        file: 'https://content.jwplatform.com/videos/og38zfyT-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/og38zfyT-1920.jpg'
      },
      {
        title: 'Hands',
        file: 'https://content.jwplatform.com/videos/bOcTPYTG-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/bOcTPYTG-1920.jpg'
      },
      {
        title: 'Lower Back/QL',
        file: 'https://content.jwplatform.com/videos/P48QBbOv-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/P48QBbOv-1920.jpg'
      },
      {
        title: 'Neck',
        file: 'https://content.jwplatform.com/videos/OrsVWPXf-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/OrsVWPXf-1920.jpg'
      },
      {
        title: 'Upper Arms',
        file: 'https://content.jwplatform.com/videos/7lEvVd3z-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/7lEvVd3z-1920.jpg'
      },
      {
        title: 'Upper Back',
        file: 'https://content.jwplatform.com/videos/vHfBZZWr-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/vHfBZZWr-1920.jpg'
      },
      {
        title: 'Adductors',
        file: 'https://content.jwplatform.com/videos/AfmS507K-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/AfmS507K-1920.jpg'
      },
      {
        title: 'Calves',
        file: 'https://content.jwplatform.com/videos/P0QGwVvf-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/P0QGwVvf-1920.jpg'
      },
      {
        title: 'Feet',
        file: 'https://content.jwplatform.com/videos/5hBdm7lt-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/5hBdm7lt-1920.jpg'
      },
      {
        title: 'Glutes',
        file: 'https://content.jwplatform.com/videos/FZ1A0fGM-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/FZ1A0fGM-1920.jpg'
      },
      {
        title: 'Tech Neck',
        file: 'https://content.jwplatform.com/videos/q9jNpoh5-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/q9jNpoh5-1920.jpg'
      },
      {
        title: 'Golf/Tennis Elbow (mini)',
        file: 'https://content.jwplatform.com/videos/Tln4xMRp-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Tln4xMRp-1920.jpg'
      },
      {
        title: 'Chest (mini)',
        file: 'https://content.jwplatform.com/videos/FputWLQp-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/FputWLQp-1920.jpg'
      },
      {
        title: 'Plantar Fasciitis',
        file: 'https://content.jwplatform.com/videos/t1kCbv8q-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/t1kCbv8q-1920.jpg'
      },
      {
        title: 'Feet (mini)',
        file: 'https://content.jwplatform.com/videos/e8dD7hAF-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/e8dD7hAF-1920.jpg'
      },
      {
        title: 'Neck (mini)',
        file: 'https://content.jwplatform.com/videos/GYZbI9Xd-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/GYZbI9Xd-1920.jpg'
      },
      {
        title: 'Lower Back Pain',
        file: 'https://content.jwplatform.com/videos/YH5vnghG-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/YH5vnghG-1920.jpg'
      },
      {
        title: 'Lower Back/QL (mini)',
        file: 'https://content.jwplatform.com/videos/sAInFHxc-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/sAInFHxc-1920.jpg'
      }
    ],
    2: [
      {
        title: 'Wave Solo First Time User',
        file: 'https://content.jwplatform.com/videos/DPTzDx7f-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/DPTzDx7f-1920.jpg'
      },
      {
        title: 'Wave Duo First Time User',
        file: 'https://content.jwplatform.com/videos/ZVMM7l3w-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/ZVMM7l3w-1920.jpg'
      },
      {
        title: 'Wave Roller First Time User',
        file: 'https://content.jwplatform.com/videos/idyNHHVj-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/idyNHHVj-1920.jpg'
      },
      {
        title: 'Wave Duo IT Bands',
        file: 'https://content.jwplatform.com/videos/D4lT2nzd-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/D4lT2nzd-1920.jpg'
      },
      {
        title: 'Wave Duo Hamstrings',
        file: 'https://content.jwplatform.com/videos/vAYlCHcS-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/vAYlCHcS-1920.jpg'
      },
      {
        title: 'Wave Duo Calves',
        file: 'https://content.jwplatform.com/videos/fSUIdOF9-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/fSUIdOF9-1920.jpg'
      },
      {
        title: 'Wave Duo Mid-Back',
        file: 'https://content.jwplatform.com/videos/HP7VOMuD-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/HP7VOMuD-1920.jpg'
      },
      {
        title: 'Wave Roller Hamstrings',
        file: 'https://content.jwplatform.com/videos/9ZzQfJiE-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/9ZzQfJiE-1920.jpg'
      },
      {
        title: 'Wave Roller IT Bands',
        file: 'https://content.jwplatform.com/videos/QHtxtRwR-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/QHtxtRwR-1920.jpg'
      },
      {
        title: 'Wave Roller Mid-Back',
        file: 'https://content.jwplatform.com/videos/qGeqjdJw-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/qGeqjdJw-1920.jpg'
      },
      {
        title: 'Wave Roller Quad',
        file: 'https://content.jwplatform.com/videos/1FlaY778-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/1FlaY778-1920.jpg'
      },
      {
        title: 'Wave Solo Calves',
        file: 'https://content.jwplatform.com/videos/Me2LpXX8-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Me2LpXX8-1920.jpg'
      },
      {
        title: 'Wave Solo Glutes',
        file: 'https://content.jwplatform.com/videos/FRDXuleM-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/FRDXuleM-1920.jpg'
      },
      {
        title: 'Wave Solo Hamstrings',
        file: 'https://content.jwplatform.com/videos/lPBjtyDZ-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/lPBjtyDZ-1920.jpg'
      },
      {
        title: 'Wave Solo Quad',
        file: 'https://content.jwplatform.com/videos/4s2ZH0xV-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/4s2ZH0xV-1920.jpg'
      },
      {
        title: 'Movement Types',
        file: 'https://content.jwplatform.com/videos/Ml7avAu0-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Ml7avAu0-1920.jpg'
      }
    ],
    3: [
      {
        title: 'How to Travel with RecoveryAir',
        file: 'https://content.jwplatform.com/videos/ohlNBbLc-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/ohlNBbLc-1920.jpg'
      },
      {
        title: 'RecoveryAir Quick Start Guide',
        file: 'https://content.jwplatform.com/videos/HxMrA2p9-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/HxMrA2p9-1920.jpg'
      },
      {
        title: 'RecoveryAir PRO Quick Start Guide',
        file: 'https://content.jwplatform.com/videos/MDod32og-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/MDod32og-1920.jpg'
      },
      {
        title: 'How to Clean',
        file: 'https://content.jwplatform.com/videos/XnCDSBqs-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/XnCDSBqs-1920.jpg'
      }
    ],
    4: []
  },
  videoSetTwo: {
    warmup: [
      {
        title: 'Full Lower Body - THERAGUN',
        file: 'https://content.jwplatform.com/videos/SVxFE5oZ-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/SVxFE5oZ-1920.jpg'
      },
      {
        title: 'Full Upper Body - THERAGUN',
        file: 'https://content.jwplatform.com/videos/c9GBME9w-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/c9GBME9w-1920.jpg'
      },
      {
        title: 'Biceps - THERAGUN',
        file: 'https://content.jwplatform.com/videos/tFK8Hgso-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/tFK8Hgso-1920.jpg'
      },
      {
        title: 'Biceps - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/Kj2MxsXd-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Kj2MxsXd-1920.jpg'
      },
      {
        title: 'Calves - THERAGUN',
        file: 'https://content.jwplatform.com/videos/R4dwaswM-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/R4dwaswM-1920.jpg'
      },
      {
        title: 'Calves - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/6LoO7QjE-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/6LoO7QjE-1920.jpg'
      },
      {
        title: 'Chest - THERAGUN',
        file: 'https://content.jwplatform.com/videos/7VrWyCIR-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/7VrWyCIR-1920.jpg'
      },
      {
        title: 'Glutes - THERAGUN',
        file: 'https://content.jwplatform.com/videos/W0LTMdvX-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/W0LTMdvX-1920.jpg'
      },
      {
        title: 'Hamstrings - THERAGUN',
        file: 'https://content.jwplatform.com/videos/gHSrwIBk-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/gHSrwIBk-1920.jpg'
      },
      {
        title: 'Hamstrings - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/VY6aHOkE-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/VY6aHOkE-1920.jpg'
      },
      {
        title: 'Lats - THERAGUN',
        file: 'https://content.jwplatform.com/videos/ErsHTduR-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/ErsHTduR-1920.jpg'
      },
      {
        title: 'Lats - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/TN5eChwP-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/TN5eChwP-1920.jpg'
      },
      {
        title: 'Shoulders - THERAGUN',
        file: 'https://content.jwplatform.com/videos/NNNoxHwL-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/NNNoxHwL-1920.jpg'
      },
      {
        title: 'Shoulders - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/qaw5GWPv-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/qaw5GWPv-1920.jpg'
      },
      {
        title: 'Quads - THERAGUN',
        file: 'https://content.jwplatform.com/videos/lBQoj5cA-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/lBQoj5cA-1920.jpg'
      },
      {
        title: 'Quads - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/bu69Cb8F-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/bu69Cb8F-1920.jpg'
      }
    ],
    recover: [
      {
        title: 'Full Lower Body - THERAGUN',
        file: 'https://content.jwplatform.com/videos/xs5ghsuY-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/xs5ghsuY-1920.jpg'
      },
      {
        title: 'Full Lower Body - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/Qcfe0z3u-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Qcfe0z3u-1920.jpg'
      },
      {
        title: 'Full Upper Body - THERAGUN',
        file: 'https://content.jwplatform.com/videos/utcJdT36-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/utcJdT36-1920.jpg'
      },
      {
        title: 'Full Upper Body - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/74SQSOh5-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/74SQSOh5-1920.jpg'
      },
      {
        title: 'Biceps - THERAGUN',
        file: 'https://content.jwplatform.com/videos/jJ1OIhAy-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/jJ1OIhAy-1920.jpg'
      },
      {
        title: 'Biceps - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/biMblfQx-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/biMblfQx-1920.jpg'
      },
      {
        title: 'Calves - THERAGUN',
        file: 'https://content.jwplatform.com/videos/ZneMrlYV-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/ZneMrlYV-1920.jpg'
      },
      {
        title: 'Calves - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/ZeHTwnw8-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/ZeHTwnw8-1920.jpg'
      },
      {
        title: 'Chest - THERAGUN',
        file: 'https://content.jwplatform.com/videos/cllwQru7-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/cllwQru7-1920.jpg'
      },
      {
        title: 'Chest - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/a5BKHM2L-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/a5BKHM2L-1920.jpg'
      },
      {
        title: 'Glutes - THERAGUN',
        file: 'https://content.jwplatform.com/videos/btM717Qd-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/btM717Qd-1920.jpg'
      },
      {
        title: 'Glutes - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/4hHGT7da-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/4hHGT7da-1920.jpg'
      },
      {
        title: 'Hamstrings - THERAGUN',
        file: 'https://content.jwplatform.com/videos/UEdC0U4m-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/UEdC0U4m-1920.jpg'
      },
      {
        title: 'Hamstrings - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/tnTqZr44-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/tnTqZr44-1920.jpg'
      },
      {
        title: 'Lats - THERAGUN',
        file: 'https://content.jwplatform.com/videos/WraANLW6-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/WraANLW6-1920.jpg'
      },
      {
        title: 'Rotators - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/f4OgvTQK-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/f4OgvTQK-1920.jpg'
      },
      {
        title: 'Shoulders - THERAGUN',
        file: 'https://content.jwplatform.com/videos/uzPkXHmQ-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/uzPkXHmQ-1920.jpg'
      },
      {
        title: 'Upper Back - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/8GPanlPS-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/8GPanlPS-1920.jpg'
      },
      {
        title: 'Quads - THERAGUN',
        file: 'https://content.jwplatform.com/videos/iQPvVemn-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/iQPvVemn-1920.jpg'
      },
      {
        title: 'Quads - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/jOQRfA8n-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/jOQRfA8n-1920.jpg'
      }
    ],
    ailments: [
      {
        title: 'Foot/Knee Discomfort - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/IWe9TlZe-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/IWe9TlZe-1920.jpg'
      },
      {
        title: 'Forearm Discomfort - THERAGUN',
        file: 'https://content.jwplatform.com/videos/lfzu8gb7-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/lfzu8gb7-1920.jpg'
      },
      {
        title: 'IT Band Discomfort - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/Y9lO3g22-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Y9lO3g22-1920.jpg'
      },
      {
        title: 'Low Back Discomfort - THERAGUN',
        file: 'https://content.jwplatform.com/videos/O4nobSLC-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/O4nobSLC-1920.jpg'
      },
      {
        title: 'Low Back Discomfort - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/IRlr5cBP-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/IRlr5cBP-1920.jpg'
      },
      {
        title: 'Neck Discomfort - THERAGUN',
        file: 'https://content.jwplatform.com/videos/Xjb0tLWi-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/Xjb0tLWi-1920.jpg'
      },
      {
        title: 'Neck Discomfort - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/X7TX7gdx-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/X7TX7gdx-1920.jpg'
      },
      {
        title: 'Shoulder Discomfort - THERAGUN',
        file: 'https://content.jwplatform.com/videos/gJr5gK4v-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/gJr5gK4v-1920.jpg'
      },
      {
        title: 'Shoulder Discomfort - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/WHGRlrLY-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/WHGRlrLY-1920.jpg'
      }
    ],
    lifestyle: [
      {
        title: 'Basketball - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/455Hrcjn-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/455Hrcjn-1920.jpg'
      },
      {
        title: 'Basketball - Warm Up - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/GVqAD5RS-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/GVqAD5RS-1920.jpg'
      },
      {
        title: 'Basketball - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/MnsUgfGp-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/MnsUgfGp-1920.jpg'
      },
      {
        title: 'Basketball - Recovery - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/JR4r0i66-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/JR4r0i66-1920.jpg'
      },
      {
        title: 'Cardio - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/hHtzbAvo-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/hHtzbAvo-1920.jpg'
      },
      {
        title: 'Cardio - Warm Up - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/rJozP0Cv-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/rJozP0Cv-1920.jpg'
      },
      {
        title: 'Cardio - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/N2bxCepZ-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/N2bxCepZ-1920.jpg'
      },
      {
        title: 'Cardio - Recovery - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/TzkXGiY0-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/TzkXGiY0-1920.jpg'
      },
      {
        title: 'Cycle - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/RqiDkwCc-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/RqiDkwCc-1920.jpg'
      },
      {
        title: 'Cycle - Warm Up - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/RRT6UFnw-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/RRT6UFnw-1920.jpg'
      },
      {
        title: 'Cycle - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/z8MPn7Cj-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/z8MPn7Cj-1920.jpg'
      },
      {
        title: 'Cycle - Recovery - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/tJxiTI0R-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/tJxiTI0R-1920.jpg'
      },
      {
        title: 'Group Exercise/Dance - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/MSoW7wvl-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/MSoW7wvl-1920.jpg'
      },
      {
        title: 'Group Exercise/Dance - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/k8qOWqt1-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/k8qOWqt1-1920.jpg'
      },
      {
        title: 'HIIT/Boxing - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/f3LQFHw4-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/f3LQFHw4-1920.jpg'
      },
      {
        title: 'HIIT/Boxing - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/P38BqJbK-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/P38BqJbK-1920.jpg'
      },
      {
        title: 'Total Body Workout - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/9DZAW3HX-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/9DZAW3HX-1920.jpg'
      },
      {
        title: 'Total Body Workout - Warm Up - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/1EbwHLwJ-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/1EbwHLwJ-1920.jpg'
      },
      {
        title: 'Total Body Workout - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/cWswIW01-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/cWswIW01-1920.jpg'
      },
      {
        title: 'Total Body Workout - Recovery - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/9Q3hdvZo-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/9Q3hdvZo-1920.jpg'
      },
      {
        title: 'Weightlifting - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/cs996SEe-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/cs996SEe-1920.jpg'
      },
      {
        title: 'Weightlifting - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/oWeBoiU6-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/oWeBoiU6-1920.jpg'
      },
      {
        title: 'Yoga/Pilates - Warm Up - THERAGUN',
        file: 'https://content.jwplatform.com/videos/L8hX9yq0-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/L8hX9yq0-1920.jpg'
      },
      {
        title: 'Yoga/Pilates - Warm Up - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/vlkb1hNj-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/vlkb1hNj-1920.jpg'
      },
      {
        title: 'Yoga/Pilates - Recovery - THERAGUN',
        file: 'https://content.jwplatform.com/videos/moyqynD8-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/moyqynD8-1920.jpg'
      },
      {
        title: 'Yoga/Pilates - Recovery - WAVE ROLLER',
        file: 'https://content.jwplatform.com/videos/p8s9ShxV-vgrxMTsP.mp4',
        image: 'https://content.jwplatform.com/thumbs/p8s9ShxV-1920.jpg'
      }
    ]
  },
  aliases: {
    retail: 'videoSetOne',
    events: 'videoSetOne',
    reset: 'videoSetTwo',
    gym: 'videoSetTwo',
  }
};