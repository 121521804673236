import { ProductTypeCard } from '../ProductTypeCard';
import { VideoCategoryCard } from '../VideoCategoryCard';
import { productTypes, videoCategories } from '../../utils/app-data';
import s from './sidebar.module.scss';

const Sidebar = ({
  activeTab,
  kioskLocation,
  selectedProductTypeId = null,
  handleSelectProductTypeId = () => {},
  selectedVideoCategory = 'warmup',
  handleSelectVideoCategory = () => {}
}) => (
  <div className={s.sidebarContainer}>
    {activeTab === 'videos' && (kioskLocation === 'gym' || kioskLocation === 'reset') ?
      videoCategories.map(cat =>
        <VideoCategoryCard
          vidCat={cat.category}
          vidCatIcon={cat.icon}
          vidCatTitle={cat.displayName}
          isDifferentOpacity={true}
          isSelected={
            selectedVideoCategory === cat.category
          }
          key={'vidCat' + cat.category}
          handleSelectVideoCategory={selectedCategory => handleSelectVideoCategory(selectedCategory)}
        />
      )
      : productTypes.map(p => (
        <ProductTypeCard
          isDifferentOpacity={true}
          isSelected={
            selectedProductTypeId === p.id
          }
          key={p.id}
          productType={p}
          isVisible={!(p.id === 4 && activeTab === 'videos')}
          onSelectProductType={() => {
            handleSelectProductTypeId(p.id);
          }}
        />
      ))
    }
  </div>
);

export default Sidebar;