import classnames from "classnames";
import { navTabs } from "../../utils/app-constants";
import s from "./topnav.module.scss";

const TopNav = ({
  activeNav = 'products',
  handleNavChange = () => {},
  handleOpenEmailCapture = () => {},
  kioskLocation = 'retail'
}) => (
  <div className={s.topNav}>
    {kioskLocation === 'gym' ?
      <div
        key={'videos-tab'}
        className={classnames(
          s.tabItem,
          activeNav === 'videos' && s.activeTab
        )}
      >
        <h2>How-To Videos</h2>
      </div>
      : navTabs.map((tab, i) => (
        <div
          key={tab.title + '-tab'}
          className={classnames(
            s.tabItem,
            activeNav === tab.title && s.activeTab
          )}
          onClick={() => handleNavChange(i)}
        >
          <h2>{tab.displayName}</h2>
        </div>
      ))}
    {/* <button className={s.emailBtn} onClick={() => handleOpenEmailCapture()}>Email Sign Up</button> */}
  </div>
);

export default TopNav;